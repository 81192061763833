$loyalty__enabled: true !default;

@import './overrides/overrides';
@import '../../bumble_base/scss/bumble-base-mobile';
@import 'template_api/navigation/email_signup_us';
@import 'layout/navigation_us';
@import 'all';
@import 'template_api/products/replenishment';
@import 'sections/account';

//One Trust Cookie Stylings
@import '../../bumble_base/scss/sections/one_trust_cookie/cookie_settings_ot';

.store-locator__hero {
  .store-locator {
    &__hero-title {
      margin-top: -26px;
      margin-#{$ldirection}: 92px;
      width: 236px;
    }
    &__search-mode {
      .store-locator {
        &__zip-search-input {
          margin-#{$ldirection}: 27%;
          width: 220px;
          height: 40px;
          font-size: 12px;
          padding: 0;
          .is-results & {
            width: 100%;
            margin: 0;
            font-size: 14px;
          }
        }
        &__toggle-intl-link {
          font-size: 14px;
          color: $color-black;
        }
        &__geo-search {
          .icon {
            top: 4px;
          }
        }
      }
    }
  }
}
